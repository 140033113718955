import { getChainId, MAIN_TOKEN_LP_ADDRESS, ROUTER_ADDRESS, TOTAL_SUPPLY, USDT_BNB_LP_ADDRESS } from "config/constants/exchange";
import { useEffect, useState } from "react";
import { getContract } from "utils/contractHelpers";
import { useNetwork } from "wagmi";
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'
import IPancakePair from 'config/abi/IPancakePair.json'
import { ChainId, FACTORY_ADDRESS_MAP } from "@pancakeswap/sdk";
import FactoryABI from 'config/abi/Factory.json'
import { provider } from 'utils/wagmi'

export default function useTokenPrice() {
    
    const [stats, setStats] = useState({
        // totalTx: 0,
        // totalPair: 0,
        // Marketcap: 0,
        tokenprice: 0
    })

    useEffect(() => {
        async function fetch() {
            try {
                // const routerContract = getContract(IPancakeRouter02ABI, ROUTER_ADDRESS[ChainId.BSC], provider(ChainId.BSC));
                // const totalTx = await routerContract.totalTx();
                // const factoryContract = getContract(FactoryABI, FACTORY_ADDRESS_MAP[ChainId.BSC], provider(ChainId.BSC));
                // const totalPair = await factoryContract.allPairsLength();

                // const bnbusdtlpContract = getContract(IPancakePair, USDT_BNB_LP_ADDRESS[ChainId.BSC], provider(ChainId.BSC));
                // const bnbreverse = await bnbusdtlpContract.getReserves();
                // const bnbreverse0 = bnbreverse[0].toString()
                // const bnbreverse1 = bnbreverse[1].toString()

                // const bnbPrice = bnbreverse0 / bnbreverse1;
                
                const lpContract = getContract(IPancakePair, MAIN_TOKEN_LP_ADDRESS[ChainId.BSC], provider(ChainId.BSC));
                const reverse = await lpContract.getReserves();
                const reverse0 = reverse[0].toString()
                const reverse1 = reverse[1].toString()

                const tokenprice = reverse0 / reverse1;
                // const tokenprice = bnbPrice / tokenbnbprice; 

                

                setStats({
                    // totalTx,
                    // totalPair,
                    // Marketcap: TOTAL_SUPPLY * tokenprice,
                    tokenprice
                })
            }
            catch (err) {
                console.log(err.message)
            }

        }

        fetch()

    }, [])

    return stats;

}